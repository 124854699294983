<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <div class="user-address">
          <div class="page-label">收货地址</div>
          <b-container fluid>
            <div class="user-address-con">
              <div class="address-add-area">
                <div class="area-title">新增收货地址</div>
                <el-form ref="form" :model="form" :rules="rules" label-width="98px">
                  <b-row>
                    <b-col sm="12" md="11" lg="10" xl="9">
                      <div class="distribution-area">
                        <div class="current-distribution-area">
                          <span>当前配送至</span>
                          <span>中国大陆</span>
                        </div>
                        <div class="change-area">切换 <i class="el-icon-arrow-right"></i></div>
                      </div>
                      <el-form-item label="地址信息:" prop="defultAddress">
                        <li-area-select v-model="form.defultAddress"></li-area-select>
                      </el-form-item>
                      <b-row>
                        <b-col sm="12" md="12" lg="8" xl="8">
                          <el-form-item label="详细地址:" prop="detailAddress">
                            <el-input v-model="form.detailAddress" placeholder="请输入详细地址信息,如道路、门牌号、小区、楼栋号、单元等信息"></el-input>
                          </el-form-item>
                        </b-col>
                        <b-col sm="12" md="12" lg="4" xl="4">
                          <el-form-item label="邮政编码:">
                            <el-input v-model="form.postalCode" placeholder="请输入编码"></el-input>
                          </el-form-item>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="12" lg="5" xl="5">
                          <el-form-item label="收货人姓名:" prop="userName">
                            <el-input v-model="form.userName" placeholder="长度不超过25个字符"></el-input>
                          </el-form-item>
                        </b-col>
                        <b-col sm="12" md="12" lg="7" xl="7">
                          <el-form-item label="手机号码:">
                            <div class="phone">
                              <el-select v-model="form.serviceRegion" placeholder="请选择">
                                <el-option
                                  v-for="item in form.serviceRegionOptions"
                                  :key="item"
                                  :label="item"
                                  :value="item">
                                </el-option>
                              </el-select>
                              <el-form-item prop="userPhone">
                                <el-input v-model="form.userPhone" placeholder="电话号码、手机号码必须填一项"></el-input>
                              </el-form-item>
                            </div>
                          </el-form-item>
                        </b-col>
                      </b-row>
                      <el-form-item size="mini" class="defult-set">
                        <el-checkbox v-model="form.defultAddressChecked">设置为默认收货地址</el-checkbox>
                      </el-form-item>
                      <el-form-item>
                        <el-button class="addressSave" @click="save('form')">保存</el-button>
                      </el-form-item>
                    </b-col>
                  </b-row>
                </el-form>
              </div>
              <div class="address-list-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>收货人</th>
                      <th>收货人地址</th>
                      <th>详细地址</th>
                      <th>邮政编码</th>
                      <th>电话/手机</th>
                      <th>操作</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in userAddressList" :key="index">
                      <td>{{item.name}}</td>
                      <td>{{item.province_name + item.city_name + item.area_name}}</td>
                      <td>{{item.detail_address}}</td>
                      <td></td>
                      <td>{{item.phone}}</td>
                      <td>
                        <div class="operation">
                          <span class="modify" @click="addressChange(item)">修改</span>
                          <span class="del" @click="del(item.address_id)">删除</span>
                        </div>
                      </td>
                      <td>
                        <div class="address-set">
                          <span v-if="item.is_default" class="default">默认地址</span>
                          <span v-else>设为默认</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-container>
        </div>
      </div>
    </div>
    <footer-nav></footer-nav>
    <el-dialog title="修改收货地址" :visible.sync="dialogVisible" width="40%" center>
      <el-form ref="addressChangeForm" :model="addressChangeForm" label-width="100px">
        <el-form-item label="选择地址：">
          <li-area-select v-model="addressChangeForm.defultAddress"></li-area-select>
        </el-form-item>
        <el-form-item label="详细地址：">
          <el-input v-model="addressChangeForm.detailAddress"></el-input>
        </el-form-item>
        <el-form-item label="收货人：">
          <el-input v-model="addressChangeForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="addressChangeForm.userPhone"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码：">
          <el-input v-model="addressChangeForm.postalCode"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="addressSave" @click="saveChange('addressChangeForm')">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  import footerNav from "@/components/Footer";
  import liAreaSelect from "@/components/liAreaSelect4";
  export default {
    components: {
      headNav,
      sideBar,
      footerNav,
      liAreaSelect
    },
    data(){
      return {
        form: {
          //默认值设置，可为空
          defultAddress: {
            sheng: '',
            shi: '',
            qu: '',
            jd: ''
          },
          detailAddress: "",
          postalCode: "",
          serviceRegionOptions: ["中国大陆+86"],
          serviceRegion: "中国大陆+86",
          defultAddressChecked: false
        },
        token: "",
        userAddressList: [],
        dialogVisible: false,
        addressChangeForm: {
          defultAddress: {
            sheng: '',
            shi: '',
            qu: '',
            jd: ''
          }
        },
        rules: {
          defultAddress: [
            { required: true, message: '请输入完整的地址信息', trigger: 'change' }
          ],
          detailAddress: [
            { required: true, message: '请输入您的详细地址', trigger: 'blur' }
          ],
          userName: [
            { required: true, message: '请输入您的姓名', trigger: 'blur' }
          ],
          userPhone: [
            { required: true, message: '请输入您的手机号码', trigger: 'blur' },
            // { type: 'phone', message: '请输入正确的手机号码', trigger: ['blur', 'change'] }
          ]
        }
        
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getUserAddress();
      },
      // 获取用户地址
      getUserAddress() {
        this.$api.getUserAddress({
          token: this.token
        }).then(res => {
          if (res.status == 100) {
            this.userAddressList = res.data;
          }
        }).catch(err => console.error(err));
      },
      // 提交修改
      addressSubmit(form){
        this.$refs[form].validate((valid) => {
          if (valid) {
            const provinceId = this.[form].defultAddress.sheng.split(",")[1],
              cityId = this.[form].defultAddress.shi.split(",")[1],
              areaId = this.[form].defultAddress.qu.split(",")[1];
            
            // 保存地址
            this.$api.saveUserAddress({
              province_id: provinceId,
              city_id: cityId,
              area_id: areaId,
              detail_address: this.[form].detailAddress,
              name: this.[form].userName,
              phone: this.[form].userPhone,
              token: this.token
            }).then(res => {
              if (res.status == 100) {
                // 清空表单数据
                for (let key in this.[form].defultAddress) {
                  this.[form].defultAddress[key] = "";
                }
                // for (let key in this.form) {
                //   this.form[key] = "";
                // }
                this.$refs[form].resetFields();
                
                this.$message({
                  type: 'success',
                  message: '地址保存成功!'
                });
                this.getUserAddress();
              }
            }).catch(err => console.error(err))
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },
      // 保存地址
      save(form){
        this.addressSubmit(form);
      },
      // 修改地址
      addressChange(obj){
        console.log(obj)
        this.dialogVisible = !this.dialogVisible;
        if(this.dialogVisible){
          this.addressChangeForm.defultAddress.sheng = obj.province_name;
          this.addressChangeForm.defultAddress.shi = obj.city_name;
          this.addressChangeForm.defultAddress.qu = obj.area_name;
          this.addressChangeForm.defultAddress.jd = "";
          this.addressChangeForm.detailAddress = obj.detail_address;
          this.addressChangeForm.userName = obj.name;
          this.addressChangeForm.userPhone = obj.phone;
          this.addressChangeForm.postalCode = "";
        }
      },
      // 保存地址修改
      saveChange(form){
        this.addressSubmit(form);
      },
      // 删除地址
      del(id) {
        this.$confirm('您确定要删除改地址吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.delUserAddress({
            address_id: id,
            token: this.token
          }).then(res => {
            if(res.status == 100){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getUserAddress();
            }
          }).catch(err => console.error(err));
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/userAddress/userAddress";
</style>
